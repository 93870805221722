<script setup lang="ts">
const toggleClick = (event: Event) => {
  const route = useRoute()

  if (route.path === '/') {
    event.preventDefault()

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}
</script>

<template>
  <div class="main-header gutter-xs">
    <HeaderMenu />
    <NuxtLinkLocale
      class="logo"
      to="/"
      @click.prevent="toggleClick"
    >
      <NuxtImg
        width="116"
        height="72"
        densities="x1 x2"
        src="/logo.png"
        format="webp"
        alt="logo Planète-Croisière.com"
      />
    </NuxtLinkLocale>

    <UiElementsButton
      color-style="link"
      class="button-phone"
      :aria-label="$t('header.contact.title')"
      :to="'tel:' + $t('phone_link')"
    >
      <UiIconsPhone />
    </UiElementsButton>
  </div>
</template>

<style scoped lang="scss">
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0px 4px 40px 0px #00000026;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: $space-sm;
    padding-right: $space-sm;
  }

  .button-phone {
    padding: 1em 2em;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }
  }
}

.logo {
  img {
    width: 116px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    order: -1;
  }
}
</style>
