<script setup lang="ts">
import GoogleTranslateSelect from '@google-translate-select/vue3'
import useIsDesktop from '~/composables/useIsDesktop'
import HeaderContact from '~/components/header/HeaderContact.vue'

const { isDesktop } = useIsDesktop()
const displayMobile = ref(false)

const isVisible = computed(() => {
  return displayMobile.value || isDesktop.value
})

const route = useRoute()

watch(
  () => route.fullPath,
  () => {
    displayMobile.value = false
  }
  ,
)

const handleClickOutside = (e) => {
  if (e.target.closest('.button-menu') == null) {
    displayMobile.value = false
  }
}
</script>

<template>
  <UiElementsButton
    aria-label="Navigation"
    class="button-menu"
    color-style="link"
    @click="displayMobile = !displayMobile"
  >
    <!--    Use v-show instead of v-if for the clickoutside closest condition -->
    <UiIconsCross v-show="displayMobile" />
    <UiIconsBars v-show="!displayMobile" />
  </UiElementsButton>

  <Transition name="slide-fade">
    <div
      v-show="isVisible"
      v-click-outside="handleClickOutside"
      class="container-menu"
    >
      <nav>
        <ul>
          <li>
            <NuxtLinkLocale to="destinations">
              {{ $t('header.menu.destinations') }}
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale to="search">
              {{ $t('header.menu.cruises') }}
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale to="cruiselines">
              {{ $t('header.menu.cruiselines') }}
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale
              class="text--color-grey-550"
              to="about"
            >
              {{ $t('header.menu.about') }}
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale
              class="text--color-grey-550"
              to="contact"
            >
              {{ $t('header.menu.contact') }}
            </NuxtLinkLocale>
          </li>
          <li class="google-translate">
            <GoogleTranslateSelect
              :default-language-code="'fr'"
              :default-page-language-code="'fr-FR'"
              :fetch-browser-language="false"
              :languages="[
                {
                  code: 'fr',
                  name: 'FR',
                  cname: '法语',
                  ename: 'French',
                },
                {
                  code: 'en',
                  name: 'EN',
                  cname: '英语',
                  ename: 'English',
                }]"
            />
          </li>
        </ul>
      </nav>
      <HeaderContact />
    </div>
  </Transition>
</template>

<style lang="scss">
.container-menu {
  position: absolute;
  z-index: 12;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 3px 8px 0px #000E5540;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    display: flex !important;
    align-items: center;
    transition: none;
    position: static;

    box-shadow: none;

    .header-contact {
      margin-left: auto;
    }
  }

  > nav {
    font-size: 1rem;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      font-size: 0.9rem;
    }

    @media (min-width: map-get($grid-breakpoints, xxl)) {
      font-size: 1rem;
    }

    > ul {
      list-style: none;
      padding: 0;
      margin: 0;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        display: flex;
      }

      > li {
        border-bottom: 1px solid var(--color-navy-30);

        &:first-child {
          border-top: 1px solid var(--color-navy-30);
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
          &, &:first-child {
            border: none;
          }
        }

        > a, > button {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 1em;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }
  }
}

.button-menu {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    display: none!important;
  }
}

.google-translate {
  display: block;

  justify-content: center;
  align-items: center;

  > .google-translate-select {
    padding: .5em;

    .google-translate-select-dropdown__activator {
      justify-content: flex-start;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    display: inline-flex;
  }
}
</style>
