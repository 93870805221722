<script setup lang="ts">
const updateFocus = () => {
  const el = document.getElementById('newsletter-email-footer-newsletter')
  const rect = el.getBoundingClientRect()

  window.scrollTo(0, rect.top - (92 + 108))
  el.focus({ focusVisible: true })
}
</script>

<template>
  <NuxtLink
    class="container-top-banner gutter-xs"
    to="#"
    @click.prevent="updateFocus()"
  >
    <div class="container-scroll">
      <div class="container-banner">
        <div class="gift">
          <UiIconsGift />
          <span>{{ $t('top_banner.title') }}</span>
        </div>
        <div v-html="$t('top_banner.description')" />
        <span class="link">{{ $t('top_banner.link_label') }}</span>
      </div>
      <div class="container-banner">
        <div class="gift">
          <UiIconsGift />
          <span>{{ $t('top_banner.title') }}</span>
        </div>
        <div v-html="$t('top_banner.description')" />
        <span class="link">{{ $t('top_banner.link_label') }}</span>
      </div>
    </div>
  </NuxtLink>
</template>

<style lang="scss">
.container-top-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  background: var(--color-navy-450);

  .container-scroll {
    display: flex;
    gap: 2em;
    white-space: nowrap;
    animation: bannerAnimation linear 14s infinite;

    @media (min-width: map-get($grid-breakpoints, md)) {
      animation: none;
      gap: 0;
      flex: 1;
    }

    @keyframes bannerAnimation {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-50% - 1em));
      }
    }
  }

  .container-banner {
    color: var(--color-primary-white);
    display: flex;
    align-items: center;
    gap: $space-xs;

    @media (min-width: map-get($grid-breakpoints, md)) {
      flex: 1;
      justify-content: space-around;
    }

    &:first-of-type {
      @media (min-width: map-get($grid-breakpoints, md)) {
        display: none;
      }
    }

    img {
      width: 20px;
    }

    .gift {
      display: flex;
      gap: 0.5rem;
    }

    .link {
      text-decoration: underline;
    }
  }
}
</style>
